<template>
  <div class="retrieve">
      <van-nav-bar
       left-arrow
       fixed
       safe-area-inset-top
       placeholder 
       title="成色表"
       @click-left="leftClick"
        />
    <img :src="img" alt="">
  </div>
</template>

<script>
import *as api from'@/utils/api'
export default {
        data() {
            return {
                img:""
            }
        },
        methods:{
        leftClick(){
            this.$router.push({
                name:"person"
            })
        }
        },
async  created(){
        let res = await api.getFlowChart("MOBILE_PWA")
        console.log(res);
        res.forEach(item=>{
            if (item.eventType =="RECYCLE_COLOR") {
                this.img = item.img
            }
        })
    }
}
</script>

<style lang="scss" scoped>

 ::v-deep{
    .van-nav-bar{
        height: 90px;
        .van-nav-bar__content{
        line-height: 90px;
        height: 90px;
        .van-nav-bar__title{
            font-size: 36px;
        }
        .van-icon{
            font-size: 36px;
            color: #1e1e1e;
        }
        }
     }}
</style>